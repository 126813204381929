"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("./main.less");
function onInputHandler(event) {
    const preview = document.getElementById('preview');
    const previewDoc = preview.contentDocument;
    const src = event.target;
    const dest = previewDoc.getElementById(src.id);
    dest.innerText = src.value;
}
window.addEventListener("load", () => {
    var _a;
    if (document.getElementById('preview')) {
        for (const el of document.getElementsByClassName('input-dispatch')) {
            console.debug(el);
            el.addEventListener('input', onInputHandler);
        }
    }
    for (const op of document.querySelectorAll('.option .preview')) {
        console.debug(op);
        const input_id = op.dataset.for;
        let handler;
        if (input_id) {
            const input = document.getElementById(input_id);
            if (!input || input === null) {
                console.error("Unable to find input for", op);
                continue;
            }
            handler = (_) => {
                input.checked = true;
            };
        }
        else {
            const p = op.parentNode;
            handler = (_) => {
                window.location.href = p.href;
            };
        }
        op.addEventListener('click', handler);
        (_a = op.contentDocument) === null || _a === void 0 ? void 0 : _a.addEventListener('click', handler);
    }
});
